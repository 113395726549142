exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ilmioita-oikeudesta-js": () => import("./../../../src/pages/ilmioita-oikeudesta.js" /* webpackChunkName: "component---src-pages-ilmioita-oikeudesta-js" */),
  "component---src-templates-blog-cat-archive-js": () => import("./../../../src/templates/BlogCatArchive.js" /* webpackChunkName: "component---src-templates-blog-cat-archive-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/BlogPostArchive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-tag-archive-js": () => import("./../../../src/templates/BlogTagArchive.js" /* webpackChunkName: "component---src-templates-blog-tag-archive-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/CategoryArchive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/Person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-reference-archive-js": () => import("./../../../src/templates/ReferenceArchive.js" /* webpackChunkName: "component---src-templates-reference-archive-js" */),
  "component---src-templates-reference-js": () => import("./../../../src/templates/Reference.js" /* webpackChunkName: "component---src-templates-reference-js" */),
  "component---src-templates-reference-practice-archive-js": () => import("./../../../src/templates/ReferencePracticeArchive.js" /* webpackChunkName: "component---src-templates-reference-practice-archive-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/Service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

