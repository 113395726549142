import { graphql, useStaticQuery } from "gatsby"
import React, { createContext } from "react"
import { useTranslation } from "react-i18next"

export const SiteContext = createContext()

const Provider = (props) => {
  const { t } = useTranslation()

  const { allPractices, allServices } =
    useStaticQuery(
      graphql`
        query allPractices {
          allPractices: allWpPractice(sort: { slug: ASC }) {
            nodes {
              id
              uri
              name
              practicesMeta {
                area
              }
            }
          }
          allServices: allWpService(sort: { slug: ASC }) {
            nodes {
              id
              uri
              title
              parentId
              practiceAreas {
                nodes {
                  slug
                }
              }
            }
          }
        }
      `
    ) || {}

  const practicesArray = allPractices.nodes.length > 0 ? allPractices.nodes : []

  const practicesGrouped = [
    {
      heading: t("Transactions"),
      headingReferences: t("Transactions References"),
      items: practicesArray.filter(
        (item) =>
          item.practicesMeta?.area === "transactions" &&
          item.name !== "Pro Bono"
      ),
    },
    {
      heading: t("Advisory"),
      headingReferences: t("Advisory References"),
      items: practicesArray.filter(
        (item) =>
          item.practicesMeta?.area === "advisory" &&
          item.name !== "Sanctions & Export Controls" &&
          item.name !== "Pro Bono"
      ),
    },
    {
      heading: t("Dispute Resolution"),
      headingReferences: t("Dispute Resolution References"),
      items: practicesArray.filter(
        (item) =>
          item.practicesMeta?.area === "dispute-resolution" &&
          item.name !== "Pro Bono"
      ),
    },
  ]

  // Services

  const servicesArray =
    allServices.nodes.length > 0
      ? allServices.nodes.filter((item) => !item.parentId)
      : []

  const servicesGrouped = [
    {
      heading: t("Transactions"),
      items: servicesArray.filter((item) => {
        const practiceAreas = item?.practiceAreas?.nodes.map(
          (area) => area.slug
        )
        return practiceAreas.includes("transactions")
      }),
    },
    {
      heading: t("Advisory"),
      items: servicesArray.filter((item) => {
        const practiceAreas = item?.practiceAreas?.nodes.map(
          (area) => area.slug
        )
        return practiceAreas.includes("advisory")
      }),
    },
    {
      heading: t("Dispute Resolution"),
      items: servicesArray.filter((item) => {
        const practiceAreas = item?.practiceAreas?.nodes.map(
          (area) => area.slug
        )
        return practiceAreas.includes("dispute-resolution")
      }),
    },
  ]

  return (
    <SiteContext.Provider
      value={{
        practicesGrouped,
        servicesGrouped,
        practices: practicesArray,
        services: servicesArray,
      }}
    >
      {props.children}
    </SiteContext.Provider>
  )
}
// eslint-disable-next-line import/no-anonymous-default-export
export default ({ element }) => <Provider>{element}</Provider>
